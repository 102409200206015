<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-created"
                label-cols-lg="2"
                :label="$t('COMMON.CREATED')"
                label-for="field-created"
              >
                <b-form-input
                  id="field-created-input"
                  :disabled="true"
                  v-model="this.tempCreated"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-changed"
                label-cols-lg="2"
                :label="$t('COMMON.CHANGED')"
                label-for="field-changed"
              >
                <b-form-input
                  id="field-changed-input"
                  :disabled="true"
                  v-model="this.tempChanged"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-feedbackType"
                label-cols-lg="2"
                :label="$t('FEEDBACKS.FEEDBACK_TYPE')"
                label-for="field-feedbackType-input"
              >
                <b-form-input
                  id="field-body-input"
                  :disabled="true"
                  v-model="this.feedbackName"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-textFeedback"
                label-cols-lg="2"
                :label="$t('FEEDBACKS.TEXT')"
                label-for="field-textFeedback-input"
              >
                <b-form-textarea
                  id="field-body-input"
                  :disabled="true"
                  v-model="dto.fields.text"
                ></b-form-textarea>
              </b-form-group>
            </b-card-body>
          </b-tab>

          <b-tab
            :title="$t('PARAMETERS.ATTACHMENT')"
            v-if="this.dto.fields.image != null"
          >
            <img :src="this.dto.fields.image" style="max-width: 100%" />
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button v-on:click="onBackClicked">Back</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "../../common";

export default {
  name: "feedback",
  components: {},
  data() {
    return {
      dto: {
        id: null,
        created: null,
        changed: null,
        feedbackTypeId: null,
        fields: [],
      },

      getFeedbackType: {
        resource:
          "/platform/api/dictionary/com.gracelogic.platform.feedback.model.FeedbackType",
        requestType: "GET",
        requestParams: {},
      },

      tempCreated: null,
      tempChanged: null,
      feedbackName: null,
      feedbackTypes: [],

      showOverlay: true,
      editStr: this.$t("FEEDBACKS.VIEW_FEEDBACK"),
    };
  },

  mounted() {
    let title = this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.FEEDBACKS"), route: "/feedback" },
      { title: title },
    ]);
  },

  created: async function () {
    if (this.$route.params.id != null) {
      await this.load();
    }

    this.loadFeedbackType();
    this.showOverlay = false;
  },

  methods: {
    load: function () {
      return this.$store
        .dispatch(API_REQUEST, {
          resource: "platform/api/feedback/" + this.$route.params.id,
          requestType: "GET",
          requestParams: {},
        })
        .then((response) => {
          this.dto = response;
          let that = this;
          that.tempCreated = Common.idObjectFormatter(
            this.dto.created,
            "CREATED"
          );
          that.tempChanged = Common.idObjectFormatter(
            this.dto.changed,
            "CHANGED"
          );
        })
        .catch(this.onError);
    },

    loadFeedbackType: function () {
      this.$store
        .dispatch(API_REQUEST, this.getFeedbackType)
        .then((response) => {
          for (const value of response) {
            this.feedbackTypes.push({
              value: value.id,
              text: value.nameLocalized,
            });
            if (value.id == this.dto.feedbackTypeId) {
              this.feedbackName = value.nameLocalized;
            }
          }
        })
        .catch(this.onError);
    },

    onBackClicked: function () {
      this.$router.push({ name: "feedbacks" });
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
